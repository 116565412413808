import {
  Body1,
  Button,
  Divider,
  Field,
  Subtitle1,
  Textarea,
  Title1,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";

interface TokenResponse {
  access_token: string;
  refresh_token: string;
  scope: string;
}

export function OAuthPage() {
  const classes = useStyles();
  const [tenantId, setTenantId] = React.useState(
    process.env.REACT_APP_OAUTH_TENANT_ID ?? ""
  );
  const [clientId, setClientId] = React.useState(
    process.env.REACT_APP_OAUTH_CLIENT_ID ?? ""
  );
  const [backendUrl, setBackendUrl] = React.useState(
    process.env.REACT_APP_OAUTH_BACKEND_URL ?? ""
  );
  const [scopes, setScopes] = React.useState(
    process.env.REACT_APP_OAUTH_SCOPES ?? ""
  );
  const [intercomUserId, setIntercomUserId] = React.useState("");
  const [authorizationCode, setAuthorizationCode] = React.useState("");
  const [token, setToken] = React.useState<TokenResponse | undefined>(
    undefined
  );

  const login = () => {
    const url = new URL(
      `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`
    );
    url.searchParams.set("client_id", clientId);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("redirect_uri", window.location.href);
    url.searchParams.set("response_mode", "query");
    url.searchParams.set("scope", scopes);
    console.log("auth request url:", url.toString());

    window.open(url.toString(), `Login to ${tenantId}`, "width=500,height=750");
  };

  const exchangeAuthCodeWithToken = () => {
    const authCode = document.getElementById("code-holder")?.innerHTML ?? "";
    const url = new URL(backendUrl);
    url.searchParams.set("code", authCode);
    url.searchParams.set("tenant_id", tenantId);
    url.searchParams.set("redirect_uri", window.location.href);
    url.searchParams.set("scope", scopes);
    url.searchParams.set("intercom_admin_user_id", intercomUserId);

    fetch(url)
      .then((res) => res.json() as Promise<TokenResponse>)
      .then((res) => {
        console.log("Got token response:", res);
        setToken(res);
      });
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const authCode = searchParams.get("code");
    if (authCode) {
      const codeHolder = window.opener.document.getElementById("code-holder");
      codeHolder.innerHTML = authCode;
      codeHolder.click();
      window.close();
      return;
    }
  }, []);

  return (
    <div className={classes.root}>
      <Title1 className={classes.title} block>
        WAY - Microsoft Authorizer
      </Title1>
      <Divider />
      <Body1 block>
        In this page, you can complete an authorization flow for Microsoft, over
        Graph API just with a simple click. Below tenant, client ID's and WAY's
        backend server's URL is already filled out for you. You can just click
        the Login button and a pop-up will be opened, requesting your
        permission. Once you approve it, the pop-up window will be closed and
        below fields will be automatically filled for you!
      </Body1>
      <Subtitle1 className={classes.tips} block>
        Tips:
      </Subtitle1>
      <ul>
        <li>
          If you have an already logged-in account, in the pop-up page, that
          account will be used when asking your consent.
        </li>
        <li>
          If you want to authorize for another user, you must log out of the
          current user first and then login from this page.
        </li>
        <li>
          Make sure that the below scopes are space-separated like:{" "}
          <code>"Chat.Read User.Read"</code>
        </li>
        <li>
          To get your intercom user ID, go to your active workspace. On the
          bottom-left corner of the screen, hover over your profile picture.
          From the appeared menu, click on the item at the top with your name.
          Then, your browser should be at an address similar to{" "}
          <code>
            https://app.eu.intercom.com/a/apps/[intercom-app-id]/admins/[intercom-user-id]
          </code>
          . From there, copy the path element <code>[intercom-user-id]</code>.
        </li>
      </ul>
      <Divider appearance="strong" />
      <div className={classes.fieldRow}>
        <Field label="Tenant ID:" required>
          <Textarea
            style={{ marginRight: "32px" }}
            className={classes.fieldShort}
            value={tenantId}
            onChange={(e, data) => setTenantId(data.value)}
          />
        </Field>
        <Field label="Client ID:" required>
          <Textarea
            className={classes.fieldShort}
            value={clientId}
            onChange={(e, data) => setClientId(data.value)}
          />
        </Field>
      </div>
      <Field label="Backend URL:" required>
        <Textarea
          className={classes.fieldLong}
          value={backendUrl}
          onChange={(e, data) => setBackendUrl(data.value)}
        />
      </Field>
      <Field label="Scopes:" required>
        <Textarea
          className={classes.fieldLong}
          value={scopes}
          onChange={(e, data) => setScopes(data.value)}
        />
      </Field>
      <Field label="Intercom User ID:">
        <Textarea
          className={classes.fieldShort}
          value={intercomUserId}
          onChange={(e, data) => setIntercomUserId(data.value)}
        />
      </Field>
      <Button className={classes.loginButton} onClick={login}>
        Login
      </Button>
      <Divider />
      <div className={classes.outputField}>
        <div className={classes.flexRow}>
          <Subtitle1 block>Authorization Code:</Subtitle1>
          <Button
            className={classes.copyButton}
            size="small"
            onClick={() => copyText(authorizationCode)}
          >
            Copy
          </Button>
        </div>
        <code
          id="code-holder"
          onClick={() => {
            setAuthorizationCode(
              document.getElementById("code-holder")?.innerHTML ?? ""
            );
            exchangeAuthCodeWithToken();
          }}
        ></code>
      </div>
      <div className={classes.outputField}>
        <div className={classes.flexRow}>
          <Subtitle1 block>Access Token:</Subtitle1>
          <Button
            className={classes.copyButton}
            size="small"
            onClick={() => copyText(token?.access_token ?? "")}
          >
            Copy
          </Button>
        </div>
        <code id="access-token">{token?.access_token}</code>
      </div>
      <div className={classes.outputField}>
        <div className={classes.flexRow}>
          <Subtitle1 block>Refresh Token:</Subtitle1>
          <Button
            className={classes.copyButton}
            size="small"
            onClick={() => copyText(token?.refresh_token ?? "")}
          >
            Copy
          </Button>
        </div>
        <code id="refresh-token">{token?.refresh_token}</code>
      </div>
      <div className={classes.outputField}>
        <div className={classes.flexRow}>
          <Subtitle1 block>Scopes:</Subtitle1>
          <Button
            className={classes.copyButton}
            size="small"
            onClick={() => copyText(token?.scope ?? "")}
          >
            Copy
          </Button>
        </div>
        <code id="scopes-output">{token?.scope}</code>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    ...shorthands.padding("24px"),
  },
  title: {
    ...shorthands.margin(0, 0, "18px", 0),
  },
  tips: {
    ...shorthands.margin("18px", 0, 0, 0),
  },
  fieldRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  fieldShort: {
    height: "35px",
    width: "400px",
  },
  fieldLong: {
    height: "35px",
    width: "700px",
  },
  loginButton: {
    ...shorthands.margin("18px", 0, "18px", 0),
  },
  outputField: {
    display: "flex",
    flexDirection: "column",
    marginTop: "18px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  copyButton: {
    marginLeft: "24px",
  },
});
