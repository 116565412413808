import React from "react";

import { Text } from "@fluentui/react-text";
import {
  Divider,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";

import {
  NewConversationDialog,
  NewConversationParameters,
} from "../components/NewConversationDialog";
import { Conversation } from "../models/Conversation";
import { ConversationBlock } from "../components/ConversationBlock";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import { TeamsfxUser } from "../models/teamsfx";
import { app } from "@microsoft/teams-js";

export function HomePage() {
  const classes = useStyles();

  const teamsfx = useTeamsFx();
  const [loading, setLoading] = React.useState(true);
  const [newConversationLoading, setNewConversationLoading] =
    React.useState(false);
  const [conversations, setConversations] = React.useState<Conversation[]>([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogError, setDialogError] = React.useState("");

  /**
   * getUserIds uses the teamsfx context from teamsjs library to retrieve
   * user ID and tenant ID, respectively.
   *
   * At the beginning, the teamsfx context is not entirely loaded so the IDs
   * are not accessible then. They do become accessible once the context is
   * automatically initialized.
   **/
  const getUserIds = React.useCallback((): [string, string] => {
    if (!teamsfx.context) {
      return ["", ""];
    }
    const user = teamsfx.context.user as TeamsfxUser;
    return [user.id, user.tenant.id];
  }, [teamsfx]);

  /**
   * onNewConversation creates a new conversation using the given parameters.
   *
   * It sends a POST request to the backend server and acts based on the response
   * status code:
   * - 400 or above*: An error occured
   * - 201: Conversation created, but the response is empty
   * - 200: Conversation created and it's in the response
   *
   * When status is 200, the delayed-loading mechanism of opening the created chat
   * is triggered, which redirects the user to the created chat.
   */
  const onNewConversation = (params: NewConversationParameters) => {
    setNewConversationLoading(true);
    const [userId, tenantId] = getUserIds();

    const host = process.env.REACT_APP_BACKEND_URL;
    fetch(`${host}/msteams/conversations/${tenantId}/${userId}`, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then(async (res) => {
        if (res.status > 400) {
          // TODO: display error on UI
          const data = await res.json();
          console.log("failed to create new conversation:", data);
          setDialogError(JSON.stringify(data));
        } else if (res.status === 201) {
          console.log("conversation created with no response");
          setShowDialog(false);
        } else if (res.status === 200) {
          console.log("conversation created with response");
          setShowDialog(false);
          return res.json() as Promise<Conversation>;
        }
      })
      .then((res) => {
        if (!res) {
          return;
        }
        setConversations([res, ...conversations]);

        app.openLink(res?.chatUrl).catch((err) => {
          console.error("failed to execute deeplink:", err);
        });

        // setShowDelayedLoading(true);
      })
      .catch((err) => {
        setDialogError(
          "Failed to create new conversation, check console for error details!"
        );
        console.error("failed to create new conversation:", err);
      })
      .finally(() => {
        setNewConversationLoading(false);
      });
  };

  // list user conversations at the beginning
  React.useEffect(() => {
    if (!loading) {
      return;
    }
    const [userId, tenantId] = getUserIds();
    if (!userId || !tenantId) {
      console.error("failed to get non-empty user ids");
      return;
    }

    const host = process.env.REACT_APP_BACKEND_URL;
    fetch(`${host}/msteams/conversations/${tenantId}/${userId}`, {
      method: "GET",
    })
      .then(async (res) => {
        if (res.status > 400) {
          // TODO: display error on UI
          const err = await res.json();
          console.error("failed to fetch user's conversations:", err);
          return;
        }
        const data = (await res.json()) as Conversation[];
        setConversations(data);
      })
      .catch((err) => {
        console.error("failed to fetch user's conversations:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamsfx, loading, getUserIds]);

  const activeConversations = conversations.filter((c) => c.open);
  const archivedConversations = conversations.filter((c) => !c.open);
  return (
    <div className={classes.root}>
      <Text size={700} weight="bold" block>
        Welcome to WAY!
      </Text>
      <div className={classes.flexRow}>
        <Text as="p" size={400} block>
          AI and Human IT support for M365 users. We can help you with all your
          IT issues. From access management to password resets and general
          hardware or software issues.
        </Text>
        <NewConversationDialog
          open={showDialog}
          onOpen={() => setShowDialog(true)}
          onClose={() => {
            setShowDialog(false);
            setDialogError("");
          }}
          error={dialogError}
          onSubmit={onNewConversation}
          loading={newConversationLoading}
        />
      </div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <Spinner
            label="Loading your conversations with WAY!"
            labelPosition="below"
            size="extra-large"
          />
        </div>
      ) : (
        <div className={classes.conversations}>
          <div className={classes.conversationsBlock}>
            <Text size={500} block>
              Active Conversations
            </Text>
            <Divider />
            {activeConversations.length === 0 ? (
              <Text>You have no active conversations.</Text>
            ) : (
              <div>
                {conversations
                  .filter((c) => c.open)
                  .map((c) => (
                    <ConversationBlock key={c.intercomId} conversation={c} />
                  ))}
              </div>
            )}
          </div>
          <div className={classes.conversationsBlock}>
            <Text size={500} block>
              Archived Conversations
            </Text>
            <Divider />
            {archivedConversations.length === 0 ? (
              <Text>You have no archived conversations.</Text>
            ) : (
              <div>
                {conversations
                  .filter((c) => !c.open)
                  .map((c) => (
                    <ConversationBlock conversation={c} />
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    ...shorthands.margin("18px"),
  },
  loadingContainer: {
    marginTop: "32px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  conversations: {
    marginTop: "18px",
  },
  conversationsBlock: {
    marginTop: "12px",
    marginBottom: "12px",
  },
});
