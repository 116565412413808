import React from "react";
import "./App.css";

import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { app } from "@microsoft/teams-js";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
  Theme,
} from "@fluentui/react-components";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { OAuthPage } from "./pages/OAuth";

export const TeamsFxContext = React.createContext<{
  theme?: Theme;
  themeString: string;
  teamsUserCredential?: TeamsUserCredential;
}>({
  theme: undefined,
  themeString: "",
  teamsUserCredential: undefined,
});

function App() {
  const { loading, theme, themeString, teamsUserCredential, inTeams } =
    useTeamsUserCredential({
      initiateLoginEndpoint:
        "https://login.microsoftonline.com/common/oauth2/v2.0",
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
    });

  React.useEffect(() => {
    app
      .initialize()
      .then(() => {
        app.notifySuccess();
      })
      .catch((err) => {
        app.notifyFailure({
          reason: app.FailedReason.Other,
          message: "Failed to load the application.",
        });
      });
  });

  const selectStyle = () => {
    if (!inTeams) {
      return teamsDarkTheme;
    }
    switch (themeString) {
      case "dark":
        return teamsDarkTheme;
      case "contrast":
        return teamsHighContrastTheme;
      default:
        return {
          ...teamsLightTheme,
          colorNeutralBackground3: "#eeeeee",
        };
    }
  };

  return (
    <TeamsFxContext.Provider
      value={{ themeString, theme, teamsUserCredential }}
    >
      <FluentProvider
        theme={selectStyle()}
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <Router>
          {!loading && (
            <Routes>
              <Route path="/msteams/oauth" element={<OAuthPage />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}

export default App;
