import React from "react";
import { Button } from "@fluentui/react-button";
import {
  DialogOpenChangeData,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-dialog";

import { TeamsFxContext } from "../App";

interface NewConversationDialogProps {
  open: boolean;
  error: string;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (params: NewConversationParameters) => void;
}

export interface NewConversationParameters {
  subject: ConversationSubject;
  description: string;
  offboardedUser: string;
  offboardingDate: Date | null | undefined;
}

export type ConversationSubject =
  | "Hardware Incident"
  | "Access Requests"
  | "Offboarding"
  | "General Question";

export function NewConversationDialog(props: NewConversationDialogProps) {
  const classes = useStyles();
  const { theme } = React.useContext(TeamsFxContext);

  const onOpenChange = (event: any, data: DialogOpenChangeData) => {
    if (data.open) {
      props.onOpen();
      console.log("submitting new conversation right away");
      props.onSubmit({
        subject: "General Question",
        description: "",
        offboardedUser: "",
        offboardingDate: null,
      });
    } else {
      props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={onOpenChange}>
      <DialogTrigger>
        <Button
          size="medium"
          appearance="primary"
          className={classes.dialogTriggerButton}
        >
          New Conversation
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Creating new conversation...</DialogTitle>
          <DialogContent>
            <div>Just a second...</div>
            {props.error && (
              <div
                className={classes.errorContainer}
                style={{ borderColor: theme?.colorStatusDangerForeground1 }}
              >
                <Text style={{ marginBottom: 12 }}>Error:</Text>
                <Text>{props.error}</Text>
              </div>
            )}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogTriggerButton: {
    width: "170px",
    height: "48px",
    ...shorthands.margin(0, 0, 0, "18px"),
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.border("1px", "solid"),
  },
  descriptionErrorContainer: {
    color: "red",
    ...shorthands.margin(0, 0, 0, "8px"),
  },
  offboardingDateRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    ...shorthands.margin("12px", "0px"),
  },
  offboardingDateField: {
    width: "60%",
  },
  offboardingDateClearButton: {
    height: "32px",
    ...shorthands.margin(0, 0, 0, "24px"),
  },
  dialogActions: {
    ...shorthands.margin("24px", 0, 0, 0),
  },
});
