import React from "react";
import { Text } from "@fluentui/react-text";
import { Conversation } from "../models/Conversation";
import {
  Button,
  Divider,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../App";

import { app } from "@microsoft/teams-js";

const formatDateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
} as Intl.DateTimeFormatOptions;

interface ConversationBlockProps {
  conversation: Conversation;
}

export function ConversationBlock(props: ConversationBlockProps) {
  const classes = useStyles();
  const { conversation } = props;
  const { theme, themeString } = React.useContext(TeamsFxContext);

  const openButtonDisabled = !conversation.chatUrl;

  const getCodeBackgroundColor = () => {
    switch (themeString) {
      case "dark":
        return "#616161";
      case "contrast":
        return "#383838";
      default:
        return theme?.colorNeutralBackground2Pressed;
    }
  };

  const formatDate = (date: Date | string) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return date.toLocaleDateString("en-us", formatDateOptions);
  };

  return (
    <div id={conversation.intercomId} className={classes.root}>
      <Text weight="bold" block className={classes.topic}>
        {conversation.topic}
      </Text>
      <div
        className={classes.bodyContainer}
        style={{ backgroundColor: getCodeBackgroundColor() }}
      >
        <Text font="monospace" block>
          {conversation.body.split("\n").map((line, i) => (
            <React.Fragment key={`line-${i}`}>
              {" "}
              {line} <br />{" "}
            </React.Fragment>
          ))}
        </Text>
      </div>
      <div className={classes.metadataContainer}>
        <Text block>
          Opened at{" "}
          <Text weight="bold">{formatDate(conversation.createdAt)}</Text>
        </Text>
        <Button
          disabled={openButtonDisabled}
          onClick={() => {
            if (openButtonDisabled) {
              return;
            }
            app
              .openLink(conversation.chatUrl)
              .then(() => {
                console.log("deeplink executed");
              })
              .catch((err) => {
                console.error("failed to execute deeplink:", err);
              });
          }}
        >
          {!openButtonDisabled
            ? "Open Conversation"
            : "No Conversation Link Available"}
        </Button>
      </div>
      <Divider />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    ...shorthands.margin("0px", "4px", "30px", "4px"),
  },
  topic: {
    ...shorthands.margin("8px", "0px"),
  },
  bodyContainer: {
    ...shorthands.padding("8px"),
  },
  metadataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.margin("8px", "0px"),
  },
});
